import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import classNames from 'classnames';
import {formatDate, formatPrice, formatShortId} from 'shared/utils.js';
import Input from 'shared/components/Input.js';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import {setFormErrors} from 'shared/effects.js';
import SeparatorLine from 'shared/components/SeparatorLine';
import TextArea from 'shared/components/TextArea.js';
import Icon from 'shared/components/Icon.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';
import DateInput from 'shared/components/DateInput.js';
import {useNotify} from 'shared/NotifyProvider.js';
import IconButton from 'shared/components/IconButton.js';
import PriceInput from 'shared/components/PriceInput.js';
import {TYPES} from 'shared/constants.js';

import {updateCashDeposit} from '../../actions.js';
import {
  handleError,
  alert,
  confirmClose,
  deleteCashDeposit,
} from '../../effects.js';
import {ReactComponent as HelpSvg} from '../../assets/icons-help.svg';
import IbanDisplay from '../../components/IbanDisplay.js';
import {ReactComponent as TrashSvg} from '../../assets/trash.svg';

import styles from './CashDepositDialog.module.scss';
import SecondTenant from './SecondTenant.js';

export default function CashDepositDialog({onHide, ...props}) {
  const [visible, setVisible] = useState(true);
  const {deposit_id: cash_deposit_id} = useParams();
  const [cash_deposit, setCashDeposit] = useState(null);
  const {notify} = useNotify();

  useEffect(() => {
    RPC('getCashDeposit', {id: cash_deposit_id})
      .then(setCashDeposit)
      .catch((err) => {
        handleError(err);
      });
  }, [cash_deposit_id]);

  const {
    register,
    control,
    reset,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const tenant_type = watch('tenant_type');
  const tenant_2_exists = watch('tenant_2_exists');

  useEffect(() => {
    reset(cash_deposit);
  }, [reset, cash_deposit]);

  const onSubmit = handleSubmit((fields) =>
    proceed({fields, cash_deposit, setError, setVisible, notify}),
  );

  const footer = (
    <div className={styles.footer}>
      <div className={styles.status_row}>
        <div className={text_styles.body1_bold_left}>Status:</div>
        <div className={text_styles.body2}>{cash_deposit?.status}</div>
        <div>
          <a
            href="https://www.getmomo.de/haeufig-gestellte-fragen/status-mietkaution"
            target="_blank"
            rel="noreferrer">
            <Icon>
              <HelpSvg />
            </Icon>
          </a>
        </div>
      </div>
      <Button
        title="Speichern"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={!isDirty}
      />
    </div>
  );

  function TrashElement() {
    return (
      <IconButton
        onClick={() =>
          deleteCashDeposit({cash_deposit, notify}).then((didDelete) => {
            if (didDelete) setVisible(false);
          })
        }>
        <TrashSvg />
      </IconButton>
    );
  }

  return (
    <Dialog
      title="Vertragsdaten"
      show={visible}
      footer={footer}
      additionalIcons={cash_deposit_id && [<TrashElement />]}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <div className={classNames(text_styles.body1_bold_left, styles.header)}>
        Angaben zur Wohneinheit
      </div>

      <Controller
        control={control}
        name="deposit_amount_cents"
        render={({field: {value, onChange, name}}) => (
          <PriceInput
            value={value}
            onChange={onChange}
            label="Kautionshöhe"
            error={errors[name]?.message}
            suffix={' €'}
            name={name}
          />
        )}
      />

      <div className={styles.row}>
        <Input
          label="Straße"
          {...register('street_name')}
          error={errors.street_name?.message}
        />
        <Input
          label="Hausnummer"
          {...register('street_number')}
          error={errors.street_number?.message}
        />
      </div>
      <div className={styles.row}>
        <Input
          label="PLZ"
          {...register('postal_code')}
          error={errors.postal_code?.message}
        />
        <Input
          label="Ort"
          {...register('region')}
          error={errors.region?.message}
        />
      </div>
      <Input value="Deutschland" label="Land" />

      <Controller
        name="start_date"
        control={control}
        render={({field: {value, onChange, name}}) => (
          <DateInput
            value={value}
            onChange={onChange}
            label="Einzugstermin laut Mietvertrag"
            error={errors[name]?.message}
            name={name}
          />
        )}
      />

      <SeparatorLine />

      <div className={classNames(text_styles.body1_bold_left, styles.header)}>
        Kautionskonto:
      </div>

      <Input
        value={formatPrice(cash_deposit?.account_balance_cents || 0)}
        label="Saldo inkl. geplanter Umsätze"
        readOnly
      />

      <IbanDisplay iban={cash_deposit?.viban} />

      <p
        className={classNames(
          text_styles.body1_bold_left,
          styles.deposit_iban_description,
        )}>
        Bitte verwenden Sie das Kautionskonto ausschließlich für die
        Kautionszahlungen von diesem Mietverhältnis.
      </p>

      <SeparatorLine />

      <div className={classNames(text_styles.body1_bold_left, styles.header)}>
        Beschreibung und Notizen
      </div>

      <Input
        label="Beschreibung Wohneinheit (optional)"
        className={styles.margin_right}
        error={errors.given_reference?.message}
        {...register('given_reference')}
      />

      <TextArea
        label="Notizen (optional)"
        placeholder="…"
        maxLength="500"
        error={errors.notes?.message}
        {...register('notes')}
      />

      <SeparatorLine />

      {tenant_type === TYPES.company && (
        <>
          <div
            className={classNames(text_styles.body1_bold_left, styles.header)}>
            Mieter
          </div>

          <Input
            label={'Name und Rechtsform'}
            {...register('tenant_company_name')}
            error={errors.tenant_company_name?.message}
          />

          <SeparatorLine />

          <div
            className={classNames(text_styles.body1_bold_left, styles.header)}>
            Geschäftsführer 1
          </div>
        </>
      )}

      {tenant_type === TYPES.individual && (
        <div className={classNames(text_styles.body1_bold_left, styles.header)}>
          {tenant_2_exists ? 'Mieter 1' : 'Mieter'}
        </div>
      )}

      <div className={styles.row}>
        <Input
          label={'Vorname'}
          {...register('tenant_1_first_name')}
          error={errors.tenant_1_first_name?.message}
        />
        <Input
          label={'Nachname'}
          {...register('tenant_1_last_name')}
          error={errors.tenant_1_last_name?.message}
        />
      </div>

      <Controller
        name={'tenant_1_date_of_birth'}
        control={control}
        render={({field: {value, onChange, name}}) => (
          <DateInput
            value={value}
            onChange={onChange}
            label={'Geburtsdatum (optional)'}
            error={errors[name]?.message}
            name={name}
          />
        )}
      />

      <SecondTenant
        errors={errors}
        control={control}
        register={register}
        watch={watch}
        setValue={setValue}
      />

      <div className={styles.details}>
        <div className={text_styles.caption_left}>
          Erstellt am{' '}
          {cash_deposit?.created_at ? formatDate(cash_deposit.created_at) : ''}
        </div>
        <div className={text_styles.caption_left} data-testid="short_id">
          Momo-ID: {formatShortId(cash_deposit?.short_id)}
        </div>
      </div>
    </Dialog>
  );
}

async function proceed({fields, cash_deposit, setError, setVisible, notify}) {
  const {id} = cash_deposit;

  try {
    await updateCashDeposit({
      deposit_id: id,
      ...fields,
    });
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (Array.isArray(err.data) && err.data.length > 0) {
        setFormErrors({
          setError,
          errors: err.data,
        });
        return;
      }
      if (err.message) {
        alert({title: err.message});
        return;
      }
    }
    handleError(err);
    return;
  }

  notify({text: 'Die Änderungen wurden gespeichert'});
  setVisible(false);
}
