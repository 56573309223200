import {createContext, useContext} from 'react';

/** @import { UseSorting } from './useSorting.js' */

function noOp() {}

/** @type {import('react').Context<TableContextValue>} */
export const TableContext = createContext({
  data: [],
  columns: [],
  sorting: null,
  toggleSorting: noOp,
  resetSorting: noOp,
  setAdvancedSorting: noOp,
  loading: true,
  actions: {},
});

/**
 * @typedef TableContextValue
 * @extends {UseSorting}
 * @property {Array<ColumnDef>} columns The definition of the columns
 * @property {TableData} data The data to be displayed
 * @property {boolean} loading Loading state of the table
 * @property {Record<string,TableAction>} [actions] Indicates if data is currently being loaded
 * @property {string} [default_action] The default action to handle clicks on a row
 */

/**
 * React hook for distributing interactivity and information throughout
 * the table component without excessive prop drilling
 * @returns {TableContextValue}
 */
export function useTableContext() {
  return useContext(TableContext);
}

/**
 * @typedef {Array<object>} TableData
 */

/**
 * Defines a column in a data grid
 * @typedef ColumnDef
 * @property {string} name
 * @property {string|React.FC|null} Header Header as a string or component, null will cause
 *                                         the header to the left to span into this column
 * @property {string|number} [width] a CSS Grid width or a pixel value
 * @property {string|Array<SortingSpec>} [sort] The name of the column to sort, or an array of objects containing the sort column and direction
 * @property {(object) => any} [accessor] If the column should be sortable
 * @property {string|null} [action] Override the action to be performed when a cell is clicked, null for no action
 * @property {ColumnDefCellComponent} [Cell] A cell React component
 */

/**
 * Cell React component
 * @callback ColumnDefCellComponent
 * @param {object} props
 * @param {any} props.value The value to be displayed in the cell
 * @returns {React.ReactNode}
 */

/**
 * @callback TableAction
 * @param {object} row
 */
